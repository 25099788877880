import React from "react"
import styled from "@emotion/styled"
import { Container } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import FooterMenu from "./FooterMenu"
import colors from "../../theme/colors"
import breakpoints from "../../theme/breakpoints"
import Logo from "../../assets/logo-white.svg"
import ButtonDonate from "../menu/ButtonDonate"
import Facebook from "./socials/Facebook"
import Instagram from "./socials/Instagram"
import Linkedin from "./socials/Linkedin"
import Twitter from "./socials/Twitter"
import Youtube from "./socials/Youtube"

export default function FooterDark() {
  const {
    acf: { social }
  } = useSiteOptions()

  const currentYear = new Date().getFullYear()

  return (
    <Footer>
      <Container>
        <FooterTop>
          <div className="wrapper-logo">
            <Logo />
          </div>
          <div className="wrapper-menu">
            <FooterMenu />
          </div>
          <Info className="wrapper-info">
            <TagLine>Hope is on the Horizon</TagLine>
            <p className="description">
              Although an ALS diagnosis has been treated as a death sentence,
              new treatments that can stop and even reverse progression are in
              development. 
            </p>
            <div className="wrapper-button-donate">
              <ButtonDonate />
            </div>
          </Info>
          <Social className="wrapper-social">
            <TagLine>Join the fight on social</TagLine>
            <div className="wrapper">
              <Linkedin url={social.linkedinUrl} />
              <Facebook url={social.facebookUrl} />
              <Twitter url={social.xUrl} />
              <Youtube url={social.youtubeUrl} />
              <Instagram url={social.youtubeUrl} />
            </div>
          </Social>
        </FooterTop>
        <div className="copyright" id="footer-bottom">
          © {currentYear} ALS Action Canada. All rights reserved. Website
          Donated by{" "}
          <a target="_blank" href="https://jambaree.com/">
            Jambaree
          </a>
        </div>
      </Container>
    </Footer>
  )
}

const Footer = styled.footer`
  z-index: 0;
  background: ${colors.abadonBlack};
  position: relative;
  padding-top: 80px;
  padding-bottom: 36px;

  @media screen and (max-width: ${breakpoints.xl}) {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .copyright {
    color: white;
    font-size: 12px;
    line-height: 20px;

    a {
      color: white;
      text-decoration: underline;
    }
  }
`

const FooterTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-bottom: 60px;

  @media screen and (max-width: ${breakpoints.xl}) {
    padding-bottom: 37px;
    gap: 49px;
    flex-wrap: wrap;

    @media screen and (max-width: ${breakpoints.xl}) {
      gap: 0;
      justify-content: space-between;
    }
  }

  .wrapper-logo {
    width: 323px;
    max-width: 100%;

    @media screen and (max-width: ${breakpoints.xl}) {
      width: 79px;
      order: 1;
    }
    svg {
      max-width: 158px;
      @media screen and (max-width: ${breakpoints.xl}) {
        width: 79px;
      }
    }
  }
  .wrapper-menu {
    @media screen and (max-width: ${breakpoints.xl}) {
      order: 2;
      margin-left: 44px;
    }
  }
  .wrapper-social {
    @media screen and (max-width: ${breakpoints.xl}) {
      order: 3;
    }
  }
  .wrapper-info {
    @media screen and (max-width: ${breakpoints.xl}) {
      order: 4;
    }
  }
`

const Info = styled.div`
  margin-top: 0px;

  color: #fff;
  width: 396px;
  max-width: 100%;

  @media screen and (max-width: ${breakpoints.xl}) {
    margin-top: 37px;
  }

  .wrapper-button-donate {
    @media screen and (max-width: ${breakpoints.xl}) {
      margin-top: 17px;
      .highlight {
        width: 100%;
        span {
          justify-content: center;
        }
      }
    }
  }

  .description {
    margin: 0 0 40px 0;
    max-width: 290px;
    font-size: 14px;
    line-height: 18px;
    @media screen and (max-width: ${breakpoints.xl}) {
      margin-bottom: 10px;
    }
  }
`

const Social = styled.div`
  color: #fff;
  @media screen and (max-width: ${breakpoints.xl}) {
    margin-top: 37px;
  }

  .wrapper {
    display: flex;
    gap: 20px;
  }
`

const TagLine = styled.p`
  margin: 0 0 31px 0;
  font-family: Inter;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  @media screen and (max-width: ${breakpoints.xl}) {
    margin: 0 0 27px 0;
    font-size: 14px;
    line-height: 20px;
  }
`

const useSiteOptions = () => {
  const {
    wp: {
      generalSettings: { title },
      siteOptions: { acf }
    }
  } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
        }
        siteOptions {
          acf {
            newsletter {
              headline
              body
            }
            social {
              headline
              body
              facebookUrl
              xUrl
              instagramUrl
              youtubeUrl
              linkedinUrl
            }
          }
        }
      }
    }
  `)

  return { title, acf }
}
