import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

export default function Twitter({ url }) {
  return (
    <Link as="a" href={url} rel="noreferrer" target="_blank">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0975609 1L15.5427 21.7142L0 38.551H3.5L17.1037 23.8058L28.0976 38.551H40L23.689 16.6748L38.1524 1H34.6585L22.128 14.5771L12.0061 1H0.0975609ZM5.2439 3.58087H10.7134L34.8598 35.964H29.3902L5.2439 3.58087Z"
          fill="white"
        />
      </svg>
    </Link>
  )
}

Twitter.propTypes = {
  url: PropTypes.string.isRequired
}

const Link = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
`
