import React from "react"
import { styled } from "@material-ui/core"
import useButtonDonate from "../../hooks/useButtonDonate"
import { LinkMenu } from "../../styles/LinkMenu"
import IconHeart from "../../assets/icon-heart.svg"

export default function ButtonDonate() {
  const { title, slug } = useButtonDonate()
  const urlLink = `${process.env.GATSBY_URL}/${slug}`

  return (
    <MenuItem className="highlight" to={urlLink}>
      <span>
        {title} <IconHeart />
      </span>
    </MenuItem>
  )
}

const MenuItem = styled(LinkMenu)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Montserrat, sans-serif";
  font-size: "16px";
  font-weight: 400;
  color: "#000";
`
