import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

export default function Facebook({ url }) {
  return (
    <Link as="a" href={url} rel="noreferrer" target="_blank">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.6895 22.2136L29.726 15.3883H23.242V10.9612C23.242 9.09345 24.1461 7.27184 27.0502 7.27184H30V1.46117C30 1.46117 27.3242 1 24.7671 1C19.4247 1 15.9361 4.26966 15.9361 10.1864V15.3883H10V22.2136H15.9361V38.7141C17.1279 38.9032 18.347 39 19.589 39C20.8311 39 22.0502 38.9032 23.242 38.7141V22.2136H28.6895Z"
          fill="white"
        />
      </svg>
    </Link>
  )
}

Facebook.propTypes = {
  url: PropTypes.string.isRequired
}

const Link = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
`
