const breakpoints = {
  xs: "320px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1268px",
  xxl: "1400px",
  xxxl: "1600px"
}

export default breakpoints
