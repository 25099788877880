import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { AppBar, LinearProgress } from "@material-ui/core"
import DesktopMenu from "components/menu/DesktopMenu"
import MobileMenu from "components/menu/MobileMenu"
import Edges from "components/edges"
import { useStore } from "store"
import MobileWrapper from "../menu/MobileWrapper"
import { breakpoints } from "../../theme/index"
import { Logo } from "./Logo"

export default function Header() {
  const [
    {
      appState: { progress }
    },
    dispatch
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [dispatch])

  return (
    <>
      <StyledAppBar position="fixed">
        <Edges size="lg">
          <Nav id="nav-menu">
            <WrapperLogo>
              <Logo />
            </WrapperLogo>
            <Right id="right">
              <DesktopMenu className="desktop-menu" />
              <MobileWrapper />
            </Right>
          </Nav>
        </Edges>
      </StyledAppBar>

      <MobileMenu />
      {progress && <StyledLinearProgress color="primary" />}
    </>
  )
}

const WrapperLogo = styled.div`
  @media screen and (max-width: ${breakpoints.xl}) {
    display: none;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const StyledAppBar = styled(AppBar)`
  && {
    background: #fff;
    min-height: 81px;
    @media (min-width: 1268px) {
      left: 50%;
      right: 0;
      max-width: 1920px;
      padding: 9px 0 5px;
      transform: translate(-50%, 0);
    }
    display: flex;
    align-items: center;
    /* z-index: 999; */
    box-shadow: none;
  }
`

const StyledLinearProgress = styled(LinearProgress)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
`

const Nav = styled.div`
  display: flex;
  align-items: start;
  justify-content: end;

  @media (max-width: ${breakpoints.xl}) {
    align-items: center;
    padding-right: 43px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    justify-content: space-between;
  }

  .desktop-menu {
    @media (max-width: ${breakpoints.xl}) {
      display: none;
    }
  }
`
