import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

export default function Youtube({ url }) {
  return (
    <Link as="a" href={url} rel="noreferrer" target="_blank">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6506 6.85667C37.3639 7.31795 38.7148 8.66885 39.1761 10.3822C40.0328 13.5123 39.9998 20.0362 39.9998 20.0362C39.9998 20.0362 39.9998 26.5271 39.1761 29.6572C38.7148 31.3706 37.3639 32.7215 35.6506 33.1827C32.5205 34.0065 19.9999 34.0065 19.9999 34.0065C19.9999 34.0065 7.51232 34.0065 4.34924 33.1498C2.6359 32.6885 1.285 31.3376 0.82372 29.6243C0 26.5271 0 20.0032 0 20.0032C0 20.0032 0 13.5123 0.82372 10.3822C1.285 8.66885 2.66885 7.285 4.34924 6.82372C7.47937 6 19.9999 6 19.9999 6C19.9999 6 32.5205 6 35.6506 6.85667ZM26.4249 20.0033L16.0131 26V14.0066L26.4249 20.0033Z"
          fill="white"
        />
      </svg>
    </Link>
  )
}

Youtube.propTypes = {
  url: PropTypes.string.isRequired
}

const Link = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
`
