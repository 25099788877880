import styled from "@emotion/styled"
import colors from "../theme/colors"

export const ButtonBase = styled.button`
  display: inline-flex;
  min-width: 200px;
  padding: 12px 23px;
  justify-content: center;
  align-items: center;

  border-radius: 64px;
  border: 2px solid #000;

  text-align: center;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  transition: all 0.2s linear;
  text-decoration: none;
  cursor: pointer;
`

// Primary Button
export const PrimaryButton = styled(ButtonBase)`
  background-color: ${colors.primary};
  color: #fff;
  border: 2px solid ${colors.primary};

  &:hover {
    border: 2px solid ${colors.primaryDark};
    background-color: ${colors.primaryDark};
  }

  &:disabled {
    background-color: ${colors.primaryLight};
    color: ${colors.textDisabled};
    border: 2px solid ${colors.primaryLight};
    cursor: not-allowed;
  }
`

// Secondary Button Dark
export const SecondaryButtonDark = styled(ButtonBase)`
  background-color: transparent;
  color: #000;

  &:hover {
    color: #fff;
    background-color: ${colors.secondaryDarkHover};
    border: 2px solid ${colors.secondaryDarkHover};
  }

  &:disabled {
    background-color: ${colors.secondaryDarkDisabled};
    color: ${colors.textDisabled};
    border: 2px solid ${colors.secondaryDarkDisabled};
    cursor: not-allowed;
  }
`

// Secondary Button Light
export const SecondaryButtonLight = styled(ButtonBase)`
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;

  &:hover {
    background-color: ${colors.secondaryLightHover};
    border: 2px solid ${colors.secondaryLightHover};
    color: #000;
  }

  &:disabled {
    background-color: ${colors.secondaryLightDisabled};
    color: ${colors.textDisabled};
    border: 2px solid ${colors.secondaryLightDisabled};
    cursor: not-allowed;
  }
`
