import React from "react"
import styled from "@emotion/styled"
import ButtonDonate from "./ButtonDonate"

export default function MobileWrapper() {
  return (
    <>
      <WrapperButtonDonate id="wrapper-button-donate">
        <ButtonDonate />
      </WrapperButtonDonate>
    </>
  )
}

const WrapperButtonDonate = styled.div`
  position: fixed;
  top: 22px;
  right: 47px;

  @media (min-width: 1268px) {
    display: none;
  }
`
