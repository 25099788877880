import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import useMenuItems from "../../hooks/useMenuItems"
import * as theme from "theme"

export default function MobileMenuSecondary({ dispatch }) {
  const { menuItems } = useMenuItems({ location: "MOBILE" })

  return (
    <WrapperMenu data-testid="mobile-menu-secondary">
      {menuItems?.map(
        ({ url, active, activeParent, label, menuitem_highlight }) => (
          <Item
            key={url}
            onClick={() => dispatch({ type: "SET_MENU", payload: false })}
            to={url}
            className={active || activeParent ? "active" : ""}
          >
            {menuitem_highlight?.icon && (
              <img
                className="icon"
                src={menuitem_highlight.icon.mediaItemUrl}
                alt=""
              />
            )}
            <Title className="item-menu" variant="subtitle1" component="span">
              {label}
            </Title>
          </Item>
        )
      )}
    </WrapperMenu>
  )
}

const WrapperMenu = styled.div`
  border-top: 1px solid #d6d6d6;
  width: 100%;
  margin-top: 36px;
  padding-top: 34px;
`

const Item = styled(Link)`
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  text-align: left;

  .arrow-red {
    display: none;
  }

  &:hover {
    .arrow-red {
      display: inherit;
    }
    .arrow-gray {
      display: none;
    }

    .item-menu {
      color: ${theme.colors.primary};
      font-weight: 700;
    }
  }
`

const Title = styled(Typography)`
  padding: 5px 0;
  color: #000;
  font-size: 20px;
  line-height: 16px;
  font-weight: 400;
  transition: color 0.2s linear;
`
