import React from "react"
import styled from "@emotion/styled"
import LogoSVG from "../../assets/logo-color.svg"
import { Link } from "gatsby"
import { useStore } from "../../store/index"
import { breakpoints } from "../../theme/index"

export function Logo({ className = "" }) {
  const [_, dispatch] = useStore()

  return (
    <LogoContainer className={className}>
      <Link
        to="/"
        onClick={() => dispatch({ type: "SET_MENU", payload: false })}
      >
        <LogoSVG />
      </Link>
    </LogoContainer>
  )
}

const LogoContainer = styled.div`
  a {
    text-decoration: none;
  }

  position: fixed;
  top: 15px;
  left: 2.5%;
  z-index: 9999;

  width: 83px;
  height: 60px;

  @media screen and (min-width: ${breakpoints.xl}) {
    /* display: none; */
    position: relative;
    top: -4px;
    left: 0;
    width: 123px;
    height: auto;
  }
`
