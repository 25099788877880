import React from "react"
import Helmet from "react-helmet"
import { MuiThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

// import app components
import Layout from "./Layout"

import { mui } from "theme"
import { StoreProvider } from "store"

export default props => {
  return (
    <StoreProvider>
      <MuiThemeProvider theme={mui}>
        <Helmet>
          <html lang="en" />
          <meta name="description" />
  <meta name="google-site-verification" content="9X1053w0RCS-CSi0m41ejuI35ml-j52dpeMFzKKyDqE" />
          
        </Helmet>

        <CssBaseline />

        <Layout {...props}>{props?.children}</Layout>
      </MuiThemeProvider>
    </StoreProvider>
  )
}
