import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { useMenuItems } from "../../hooks/useMenuItems"
import * as theme from "theme"

export default function MenuSecondary() {
  const { menuItems } = useMenuItems({ slug: "secondary-menu" })

  return (
    <Nav id="nav-secondary-menu">
      {menuItems?.map((menuItem) => (
        <MenuItem key={menuItem.url} to={menuItem.url}>
          {menuItem.label}
        </MenuItem>
      ))}{" "}
    </Nav>
  )
}

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  gap: 39px;
`

const MenuItem = styled(Link)`
  color: ${theme.colors.darkGrey};
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
