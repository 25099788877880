module.exports = [{
      plugin: require('../node_modules/gatsby-source-wordpress/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"timeout":999999,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":false,"hardCacheData":false},"url":"https://alscanada.wpengine.com/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gravity-forms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://alscanada.wpengine.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Als Action Canada","short_name":"alsactioncanada","start_url":"/","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1a1af61d3658f52122474df2e20833ea"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
