import styled from "@emotion/styled"
import { Link } from "gatsby"
import { colors } from "../theme/index"

export const LinkMenu = styled(Link)`
  padding: 5px 17px;
  color: ${colors.secondary};
  span {
    font-size: 16px;
    line-height: 1;
  }

  &:hover {
    span {
      color: ${colors.primary};
    }
  }

  &.highlight {
    text-decoration: none;
    display: inline-block;
    span {
      display: flex;
      padding: 6px 12px;
      line-height: 1;
      align-items: center;
      gap: 8px;
      font-weight: 700;
      font-family: Montserrat;
    }

    border-radius: 64px;
    color: white;
    background-color: ${colors.primary};
    transition: all 0.25s ease;

    &:hover {
      background-color: ${colors.primaryDark};
      span {
        color: white;
      }
    }
  }
`
