import React from "react"
import { Global, css } from "@emotion/react"
import styled from "@emotion/styled"
import { HamburgerMenu } from "../../components/menu/HamburgerMenu"

// import app components
import Header from "components/header"
import { Seo } from "components/SEO"
import { Logo } from "../header/Logo"
import { dimensionConstants } from "../../theme/dimensionConstants"
import { breakpoints } from "../../theme/index"
import NewsletterStatic from "../footer/NewsletterStatic"
import FooterDark from "../footer/FooterDark"

export default function Layout(props) {
  const { pageContext, location } = props

  return (
    <Container id="main-container">
      <Global
        styles={css`
          body {
            background-color: #fff;
          }
        `}
      />
      <Seo {...pageContext} {...location} />

      <HamburgerMenu />
      <WrapperLogo>
        <Logo className="logo-desktop" />
      </WrapperLogo>
      <Header />
      <Main>{props?.children}</Main>
      <NewsletterStatic />
      <FooterDark />
    </Container>
  )
}

const WrapperLogo = styled.div`
  @media screen and (min-width: ${breakpoints.xl}) {
    display: none;
  }
`

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto 0;
  background: #fff;
  position: relative;
  overflow-x: hidden;

  #jointhefight {
    border-top: 120px solid transparent;
    margin-top: -120px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
  }
`

const Main = styled.main`
  padding-top: ${dimensionConstants.header.mobile};

  @media (min-width: ${breakpoints.xl}) {
    padding-top: ${dimensionConstants.header.desktop};
  }
`
