import styled from "@emotion/styled"
import { Container } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { breakpoints, colors } from "../../theme"
import { SecondaryButtonLight } from "../../styles/ButtonBase"

export default function NewsletterStatic() {
  const {
    acf: { newsletter }
  } = useSiteOptions()

  return (
    <Section data-testid="newsletter">
      <Wrapper>
        <div>
          <Heading className="title">{newsletter.headline}</Heading>
          <Body>{newsletter.body}</Body>
        </div>
        <WrapperLink>
          <SecondaryButtonLight
            as="a"
            href={newsletter?.link?.url}
            target={newsletter?.link?.target}
          >
            {newsletter?.link?.title}
          </SecondaryButtonLight>
        </WrapperLink>
      </Wrapper>
    </Section>
  )
}

const Section = styled.section`
  background-color: ${colors.primary};
  padding-top: 62px;
  padding-bottom: 52px;

  @media (max-width: ${breakpoints.xl}) {
    padding: 50px 24px 56px;
  }
`

const Wrapper = styled(Container)`
  display: grid;
  grid-template-columns: 813px 1fr;
  gap: 84px;

  @media (max-width: ${breakpoints.xl}) {
    grid-template-columns: 1fr;
    gap: 22px;
  }
`

const WrapperLink = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.xl}) {
    gap: 44px;
    justify-content: center;
  }
`

const Heading = styled("h2")`
  font-size: 48px;
  line-height: 52px;
  color: #fff;
  font-weight: 400;

  @media (max-width: ${breakpoints.xl}) {
    margin: 0 0 14px 0;
    text-align: center;
  }
`

const Body = styled("p")`
  font-size: 20px;
  line-height: 30px;
  color: #fff;

  @media (max-width: ${breakpoints.xl}) {
    text-align: center;
  }
`

const useSiteOptions = () => {
  const {
    wp: {
      generalSettings: { title },
      siteOptions: { acf }
    }
  } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
        }
        siteOptions {
          acf {
            newsletter {
              headline
              body
              link {
                title
                target
                url
              }
            }
          }
        }
      }
    }
  `)

  return { title, acf }
}
