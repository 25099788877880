import React from "react"
import styled from "@emotion/styled"
import Parser from "html-react-parser"
import { Link } from "gatsby"
import { Typography, Button } from "@material-ui/core"
import * as theme from "theme"
import { useMenuItems } from "../../hooks/useMenuItems"
import { LinkMenu } from "../../styles/LinkMenu"
import ButtonDonate from "./ButtonDonate"

const MenuMain = (props) => {
  const { menuItems, menuFields } = useMenuItems({ location: "MAIN_MENU" })

  return (
    <Menu {...props}>
      <Items>
        {menuItems?.map(({ url, label, childItems, menuitem_highlight }) => {
          return (
            <MenuItem key={url}>
              {childItems?.nodes.length ? (
                <>
                  <LinkMenu to={url}>
                    <Typography component="div" children={label} />
                  </LinkMenu>

                  <SubMenu className="sub-menu">
                    {childItems.nodes.map((o, i) => (
                      <SubMenuItem key={i} to={url}>
                        <Typography
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold"
                          }}
                          color="inherit"
                          component="div"
                          children={o.label}
                        />
                      </SubMenuItem>
                    ))}
                  </SubMenu>
                </>
              ) : menuitem_highlight?.highlight ? (
                <ButtonDonate />
              ) : (
                <LinkMenu
                  className={menuitem_highlight?.highlight ? "highlight" : ""}
                  key={url}
                  to={url}
                >
                  <Typography component="span">{label}</Typography>
                </LinkMenu>
              )}
            </MenuItem>
          )
        })}

        {menuFields?.ctaButton && (
          <Button
            style={{ color: "#fff", marginLeft: "15px" }}
            component="a"
            href={menuFields?.ctaButton?.url}
            target={menuFields?.ctaButton?.target}
          >
            {menuFields?.ctaButton?.title &&
              Parser(menuFields?.ctaButton?.title)}
          </Button>
        )}
      </Items>
    </Menu>
  )
}

export default MenuMain

const Menu = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }

  .active-menu-link {
    color: ${theme.colors.primary};
  }
`

const Items = styled.div`
  display: flex;
  align-items: center;
`

const MenuItem = styled.div`
  display: flex;
  position: relative;

  &:hover {
    .sub-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
  .sub-menu {
    opacity: 0;
    pointer-events: none;
  }
`

const SubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: -20px;
  background: ${theme.colors.primary};
  padding: 10px 0;

  .active-item {
    color: white;
  }
`

const SubMenuItem = styled(Link)`
  padding: 10px 30px;
  flex-shrink: 0;
  white-space: nowrap;
  color: #e4f2f5;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  > div {
    font-size: 14px;
  }
`
