import React from "react"
import styled from "@emotion/styled"
import MenuMain from "./MenuMain"
import MenuSecondary from "./MenuSecondary"
import breakpoints from "../../theme/breakpoints"

const DesktopMenu = () => {
  return (
    <WrapperMenu id="menus-header">
      <MenuSecondary />
      <MenuMain />
    </WrapperMenu>
  )
}

export default DesktopMenu

const WrapperMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;

  @media screen and (max-width: ${breakpoints.xl}) {
    display: none;
  }
`
