exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-templates-archive-memorial-js": () => import("./../../../src/templates/archive/Memorial.js" /* webpackChunkName: "component---src-templates-archive-memorial-js" */),
  "component---src-templates-archive-post-js": () => import("./../../../src/templates/archive/Post.js" /* webpackChunkName: "component---src-templates-archive-post-js" */),
  "component---src-templates-archive-story-detail-js": () => import("./../../../src/templates/archive/StoryDetail.js" /* webpackChunkName: "component---src-templates-archive-story-detail-js" */),
  "component---src-templates-archive-story-js": () => import("./../../../src/templates/archive/Story.js" /* webpackChunkName: "component---src-templates-archive-story-js" */),
  "component---src-templates-single-page-index-js": () => import("./../../../src/templates/single/page/index.js" /* webpackChunkName: "component---src-templates-single-page-index-js" */),
  "component---src-templates-single-post-index-js": () => import("./../../../src/templates/single/post/index.js" /* webpackChunkName: "component---src-templates-single-post-index-js" */)
}

