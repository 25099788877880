import React, { useEffect } from "react"
import { Typography, Button } from "@material-ui/core"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import ArrowGray from "../../assets/arrow-gray.svg"
import ArrowRed from "../../assets/arrow-red.svg"
import { useStore } from "store"
import * as theme from "theme"

import { useMenuItems } from "../../hooks/useMenuItems"
import MobileMenuSecondary from "./MobileMenuSecondary"
import ButtonDonate from "./ButtonDonate"
import { Logo } from "../header/Logo"

const MobileMenu = (props) => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  const { menuItems, menuFields } = useMenuItems({ location: "MAIN_MENU" })

  return (
    <Menu
      {...props}
      menuState={menu}
      style={{
        overflowY: "auto"
      }}
    >
      <Logo className="logo-mobile" />
      {menuItems?.map(({ id, url, active, activeParent, label }) => (
        <Item
          key={url}
          onClick={() => dispatch({ type: "SET_MENU", payload: false })}
          to={url}
          className={active || activeParent ? "active" : ""}
        >
          <Title className="item-menu" variant="subtitle1" component="span">
            {label}
          </Title>
          <ArrowGray />
          <ArrowRed />
        </Item>
      ))}
      <MobileMenuSecondary dispatch={dispatch} />
      {menuFields?.ctaButton?.url && (
        <Button
          component={Link}
          to={menuFields?.ctaButton.url}
          variant="contained"
          style={{ margin: "30px auto" }}
          onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        >
          {menuFields?.ctaButton.title}
        </Button>
      )}
      <WrapperButtonDonate>
        <ButtonDonate />
      </WrapperButtonDonate>
    </Menu>
  )
}

export default MobileMenu

const menuClose = css`
  transform: translateY(-110%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateY(0);
  opacity: 1;
`

const Menu = styled.div`
  ${(props) => (props.menuState ? menuOpen : menuClose)}
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition:
    transform 0.4s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  background: #fff;
  padding-top: 100px;
  top: 0px;
  z-index: 1100;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    top: 0px;
  }
`

const Item = styled(Link)`
  display: flex;
  gap: 7px;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  text-align: left;

  .arrow-red {
    display: none;
  }

  &:hover {
    .arrow-red {
      display: inherit;
    }
    .arrow-gray {
      display: none;
    }

    .item-menu {
      color: ${theme.colors.primary};
      font-weight: 700;
    }
  }

  &:last-of-type {
    display: none;
  }
`

const Title = styled(Typography)`
  padding: 5px 0;
  color: #000;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1;
  font-weight: 400;
  transition: color 0.2s linear;
`

const WrapperButtonDonate = styled.div`
  margin-top: 36px;
  padding: 0 24px;
  width: 100%;
  padding-bottom: 36px;

  .highlight {
    display: block;
    span {
      justify-content: center;
    }
  }
`
