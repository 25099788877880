import React from "react"
import styled from "@emotion/styled"
import Parser from "html-react-parser"
import { Link } from "gatsby"
import { useMenuItems } from "../../hooks/useMenuItems"

export default function FooterMenu() {
  const { menuItems } = useMenuItems({ location: "FOOTER" })

  return (
    <Container>
      {menuItems?.map((item) => {
        return (
          <MenuItem key={item.url} to={item.url}>
            {item?.label && Parser(item.label)}
          </MenuItem>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  min-width: 240px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 959px) {
    display: grid; /* Use grid layout for mobile */
    grid-template-columns: repeat(2, 1fr); /* Two columns in mobile */
    column-gap: 32px;
    min-width: 0;
  }
`
const MenuItem = styled(Link)`
  color: white;
  text-decoration: none;
  font-family: Inter;
  &:hover {
    text-decoration: underline;
  }
`
