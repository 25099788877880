import { graphql, useStaticQuery } from "gatsby"

interface QueryUseButtonDonate {
  wpPage: {
    title: string
    slug: string
  }
}

export default function useButtonDonate() {
  const {
    wpPage: { title, slug }
  }: QueryUseButtonDonate = useStaticQuery(graphql`
    {
      wpPage(slug: { eq: "donate" }) {
        title
        slug
      }
    }
  `)

  return { title, slug }
}
