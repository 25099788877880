const colors = {
  primary: "#EE3A3B", // Main primary color
  primaryDark: "#AC1F1F", // Darker shade for primary button hover state
  primaryLight: "#FFBDBD", // Lighter shade for primary button disabled state

  secondary: "#282829", // Secondary color base (used for dark buttons)
  secondaryDarkHover: "#1F1F1F", // Hover state for dark secondary buttons
  secondaryDarkDisabled: "#4C4C4C", // Disabled state for dark secondary buttons

  abadonBlack: "#231F20", // Black shade used in designs
  grey: "#77787B", // Standard grey
  darkGrey: "#5A5A5A", // Darker grey, can be used for text or disabled state
  lightGrey: "#E0E0E0", // Light grey, suitable for disabled states

  secondaryLightHover: "#FFF", // Hover state for light secondary buttons
  secondaryLightDisabled: "#3C3C3C", // Disabled state for light secondary buttons

  textDisabled: "#9A9A9A", // Disabled text color
  success: "green", // Success color
  error: "red" // Error color
}

export default colors
